<template>
  <nav class="w-full fixed top-0 mx-auto inset-x-0 z-50 backdrop-filter backdrop-blur-lg bg-opacity-70 bg-white">
    <div class="max-w-7xl w-full mx-auto px-4">
      <div class="flex justify-between items-center text-white h-16">
        <img src="../assets/logo.png" alt="Logo" class="h-9 md:h-10 cursor-pointer" @click="$router.push({name: 'home'})">

        <div class="flex items-center gap-x-5">
          <a href="https://wa.me/77766906090?text=Здравствуйте, проконсультируйте меня пожалуйста. " target="_blank" class="cursor-pointer">
            <whatsapp/>
          </a>

          <cart/>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import Cart from "@/assets/cart.vue";
import Whatsapp from "@/assets/whatsapp.vue";

export default {
  name: "Navbar",
  components: {Whatsapp, Cart},
}
</script>