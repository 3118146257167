export function uglifyPhone(phone) {
    if (phone) {
        return phone.replace(/\D/g, '')
    }

    return ''
}

export function uglifyIin(iin) {
    if (iin) {
        return iin.replace(/\D/g, '')
    }

    return ''
}
