<template>
  <div class="max-w-5xl w-full mx-auto mt-24 font-oswald px-4">
    <div v-if="totalCount === 0">
      <p class="text-xl mb-4">Ваша корзина пуста</p>
      <button
          @click="$router.push({name: 'home'})"
          class="bg-black hover:bg-white hover:text-black hover:border-black border text-white text-sm px-6 md:px-8 py-2 md:py-2.5 rounded-2xl uppercase flex">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
        </svg>

        <span class="ml-1">назад к меню</span>
      </button>
    </div>

    <div v-else>
      <div class="w-full mb-5">
        <p class="text-2xl">Оформление заказа</p>
      </div>

      <div class="flex flex-col md:flex-row gap-10">

        <form class="md:w-2/3" @submit.prevent="submitOrder">
          <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mb-6">
            <div class="sm:col-span-3">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Имя</label>
              <div class="mt-2">
                <input v-model="form.name" :disabled="loading" type="text" required name="name" id="name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Телефон</label>
              <div class="mt-2">
                <input
                    v-model="form.phone"
                    type="text"
                    :disabled="loading"
                    name="phone"
                    required
                    id="phone"
                    autocomplete="given-name"
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    v-maska:[options]
                >
                <p class="text-xs text-red-500">{{errors.phone}}</p>
              </div>
            </div>

            <div class="sm:col-span-1 sm:col-start-1">
              <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Город</label>
              <div class="mt-2">
                <input type="text" disabled value="Астана" name="city" id="city" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="address" class="block text-sm font-medium leading-6 text-gray-900">Адрес</label>
              <div class="mt-2">
                <input type="text" v-model="form.address" :disabled="loading" required name="address" id="address" autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="flat" class="block text-sm font-medium leading-6 text-gray-900">Квартира</label>
              <div class="mt-2">
                <input type="text" v-model="form.flat" :disabled="loading" name="flat" id="flat" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="floor" class="block text-sm font-medium leading-6 text-gray-900">Этаж</label>
              <div class="mt-2">
                <input type="number" v-model="form.floor" :disabled="loading" name="floor" id="floor" autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div class="sm:col-span-3 sm:col-start-1">
              <label for="time" class="block text-sm font-medium leading-6 text-gray-900">Выберите время на завтра</label>
              <div id="time" class="mt-2 flex gap-4 w-full text-xs md:text-sm">
                <div
                  v-for="i in intervals"
                  :key="i.id"
                  class="p-2 rounded-2xl border border-black cursor-pointer"
                  :class="{'bg-black text-white': form.interval === i.id}"
                  @click="selectInterval(i.id, i.name)"
                >
                  {{i.name}}
                </div>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="payment" class="block text-sm font-medium leading-6 text-gray-900">Выберите способ оплаты</label>
              <div id="payment" class="mt-2">
                <div class="flex items-center mb-3">
                  <input
                      id="kaspiPay"
                      v-model="form.paymentType"
                      value="1"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                  <label for="kaspiPay" class="ml-3 block text-sm text-gray-700">
                    Удаленная оплата Kaspi Pay
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                      id="cashless"
                      v-model="form.paymentType"
                      value="2"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                  <label for="cashless" class="ml-3 block text-sm text-gray-700">
                    Безналичный расчет по договору
                  </label>
                </div>
              </div>
              <p v-if="cantBuy" class="mt-2 text-xs text-red-500">
                Минимальная сумма заказа для наших партнеров 10 000 тенге
              </p>
            </div>
          </div>

          <button
              :disabled="loading || cantBuy || cantBuyTime"
              class="flex items-center gap-3 bg-black hover:bg-white hover:text-black disabled:bg-gray-200 disabled:border-none disabled:text-black disabled:cursor-not-allowed border border-black text-white text-xs md:text-sm py-3 text-center rounded-2xl uppercase px-6 mb-3"
          >
              <svg v-if="loading" :class="{'animate-spin': loading}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>

            Оформить заказ
          </button>
          <div v-if="cantBuyTime" class="mt-4 text-sm p-4 border-l-4 border-black rounded border flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
            </svg>

            Прием заказов по Астане осуществляется только c 10:00 до 18:00
          </div>
        </form>

        <div class="md:w-1/3 h-fit bg-gray-100 rounded-2xl px-4 py-6">
          <p class="mb-3 text-xl">Ваш заказ:</p>

          <div class="flex items-center justify-between mb-2">
            <p class="font-light">Сумма заказа</p>
            <p class="text-lg">{{totalPrice}}тг</p>
          </div>

          <div class="flex items-center justify-between mb-4">
            <p class="font-light">Доставка</p>
            <p class="text-lg">{{delivery}}тг</p>
          </div>

          <div class="flex items-center justify-between">
            <p>Итого</p>
            <p class="text-lg">{{totalPrice + delivery}}тг</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <success-modal :showSuccess="showSuccess" @close="closeModal"/>
  <fail-modal :showFail="showFail" @close="closeModal"/>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {vMaska} from "maska";
import axios from "axios";
import SuccessModal from "@/components/SuccessModal.vue";
import FailModal from "@/components/FailModal.vue";
import {uglifyPhone} from "@/helpers/dataFormat";

export default {
  name: 'Checkout',
  components: {FailModal, SuccessModal},
  directives: { maska: vMaska },
  data: () => ({
    form: {
      name: null,
      phone: '',
      address: null,
      flat: null,
      floor: null,
      interval: 1,
      paymentType: '1',
      time: '06:30 - 07:30',
      items: [],
      deliveryCost: 600,
      city_id: 1,
      brand_id: 1
    },
    loading: false,
    options: {
      mask: "+7 (###) ###-##-##",
      eager: true
    },
    errors: {
      phone: null,
      name: null,
      address: null
    },
    intervals: [
      {
        id: 1,
        name: '06:30 - 07:30'
      },
      {
        id: 2,
        name: '07:30 - 08:30'
      },
      {
        id: 3,
        name: '08:30 - 09:30'
      },
    ],
    paymentTypes: [
      {
        id: 1,
        name: 'Удаленная оплата Kaspi Pay'
      },
      {
        id: 2,
        name: 'Безналичный расчет по договору'
      }
    ],
    showSuccess: false,
    showFail: false,
  }),
  computed: {
    ...mapState({
      cart: 'cart'
    }),
    ...mapGetters({
      totalCount: "getTotalCount",
      total: 'getTotal',
      wholesale: 'getWholesale'
    }),
    cantBuy() {
      return (this.wholesale < 10000 && this.form.paymentType === '2')
    },
    cantBuyTime() {
      return new Date().getHours() >= 19 || new Date().getHours() < 10
    },
    totalPrice() {
      return this.form.paymentType === '1' ? this.total : this.wholesale
    },
    delivery() {
      return this.form.paymentType === '1' ? this.form.deliveryCost : 0
    }
  },
  methods: {
    submitOrder() {
      if (this.form.phone.length < 18) {
        this.errors.phone = 'Зполните поле'
        return
      }

      this.errors.phone = null
      this.loading = true

      this.form.address = this.form.address + ', кв: ' + this.form.flat + ', этаж: ' + this.form.flat
      this.form.phone = uglifyPhone(this.form.phone)

      this.form.items = this.cart.map(x => {
        return {
          productId: x.id,
          quantity: x.q
        }
      })

      axios.post('https://admin.eatandchill.kz/api/orders', this.form)
          .then(res => {
            this.loading = false
            this.showSuccess = true
            this.$store.dispatch('clearCart');
          }).catch(err => {
            this.loading = false
            this.showFail = true
          }).finally(() => {
            this.loading = false
          })
    },
    closeModal(){
      this.showSuccess = false
      this.showFail = false
    },
    selectInterval(id, val) {
      this.form.interval = id
      this.form.time = val
    }
  }
}
</script>