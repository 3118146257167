<template>
  <div class="max-w-4xl w-full mx-auto mt-24 font-oswald px-4 py-6">
    <div v-if="totalCount === 0">
      <p class="text-xl mb-4">Ваша корзина пуста</p>
      <button
          @click="$router.push({name: 'home'})"
          class="bg-black hover:bg-white hover:text-black hover:border-black border text-white text-sm px-6 md:px-8 py-2 md:py-2.5 rounded-2xl uppercase flex">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
        </svg>

        <span class="ml-1">назад к меню</span>
      </button>
    </div>

    <div v-else>
      <div class="w-full mb-5">
        <p class="text-2xl">Корзина</p>
      </div>

      <div class="flex flex-col md:flex-row gap-10">

        <div class="md:w-2/3">
          <div
              v-for="item in cart"
              :key="item.id"
              class="flex gap-1 items-center mb-10 w-full"
          >
            <div class="w-40 md:w-1/3">
              <img
                  :src="'https://admin.eatandchill.kz'+item.image"
                  class="w-40 h-40 object-cover rounded-2xl"
                  :alt="item.name"
              />
            </div>

            <div class="w-full md:w-2/3">
              <p class="text-xl">{{item.name}}</p>
              <p class="font-light mb-4">{{item.price}}тг</p>

              <div class="flex items-center justify-between">
                <div class="flex items-center gap-3">
                  <button class="bg-black rounded" @click="decrement(item.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                    </svg>
                  </button>

                  <span class="text-lg">{{item.q}}</span>

                  <button class="bg-black rounded" @click="increment(item.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                  </button>
                </div>

                <p class="text-xl">{{ item.total }}тг</p>
              </div>
            </div>
          </div>

          <div v-if="cantBuyTime" class="mt-4 text-sm p-4 border-l-4 border-black rounded border flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
            </svg>

            Прием заказов по Астане осуществляется только c 10:00 до 18:00
          </div>
        </div>

        <div class="md:w-1/3 h-fit bg-gray-100 rounded-2xl px-4 py-6">
          <p class="mb-3 text-xl">Ваш заказ:</p>

          <div class="flex items-center justify-between mb-2">
            <p class="font-light">Сумма заказа</p>
            <p class="text-lg">{{total}}тг</p>
          </div>

          <div class="flex items-center justify-between mb-4">
            <p class="font-light">Доставка</p>
            <p class="text-lg">{{delivery}}тг</p>
          </div>

          <div class="flex items-center justify-between mb-10">
            <p>Итого</p>
            <p class="text-lg">{{total + delivery}}тг</p>
          </div>

          <button
              @click="$router.push({name: 'checkout'})"
              :disabled="cantBuyTime"
              class="bg-black hover:bg-white hover:text-black border border-black disabled:bg-gray-200 disabled:border-none disabled:text-black disabled:cursor-not-allowed text-white text-xs md:text-sm py-3.5 text-center rounded-2xl uppercase flex justify-center w-full mb-3">
            Оформить заказ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";

export default {
  data: () => ({
    delivery: 600
  }),
  computed: {
    ...mapState({
      cart: 'cart'
    }),
    ...mapGetters({
      totalCount: "getTotalCount",
      total: 'getTotal'
    }),
    cantBuyTime() {
      return new Date().getHours() >= 19 || new Date().getHours() < 10
    },
  },
  methods: {
    increment(id) {
      this.$store.dispatch('increment', id)
    },
    decrement(id) {
      this.$store.dispatch('decrement', id)
    }
  }
}
</script>