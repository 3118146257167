<template>
  <!-- Hero -->
  <div class="max-w-7xl mx-auto w-full relative h-screen max-h-[1000px] flex items-center justify-center gap-8 font-oswald">

    <div class="md:w-1/2 w-full rounded-2xl backdrop-filter backdrop-blur-sm bg-opacity-30 bg-white p-5">
      <span class="bg-green-600 rounded-2xl px-4 py-1 text-white text-xs">Халал</span>
      <h1 class="text-3xl md:text-4xl lg:text-5xl font-medium rounded-2xl mt-4">
        Ресторанные блюда в шоковой заморозке
      </h1>

      <p class="font-light md:text-lg mt-4 md:max-w-sm mb-7">
        Мы готовим изысканные блюда, которые Вы можете быстро подать к столу
      </p>

      <button @click="scrollDown" class="bg-black hover:bg-white hover:text-black hover:border-black border text-white text-sm px-6 md:px-8 py-2 md:py-2.5 rounded-2xl uppercase manrope mb-16 flex">
        <span class="mr-1">Заказать</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
        </svg>
      </button>

      <div class="flex flex-col lg:flex-row gap-4">
        <div class="rounded-2xl py-3 px-5 flex items-center backdrop-filter backdrop-blur-lg bg-opacity-30 bg-gray-300">
          <div class="flex items-center">
              <span class="text-xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                01
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="ml-3 text-sm">Разрежьте упаковку</p>
        </div>

        <div class="rounded-2xl py-3 px-5 flex items-center backdrop-filter backdrop-blur-lg bg-opacity-30 bg-gray-300">
          <div class="flex items-center">
              <span class="text-xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                02
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="ml-3 text-sm">Разогрейте в течение 5-8 минут</p>
        </div>

        <div class="rounded-2xl py-3 px-5 flex items-center backdrop-filter backdrop-blur-lg bg-opacity-30 bg-gray-300">
          <div class="flex items-center">
              <span class="text-xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                03
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="ml-3 text-sm">Наслаждайтесь</p>
        </div>
      </div>
    </div>

    <div class="absolute -z-20 inset-0 w-full md:w-1/2 md:relative">
      <video autoplay playsinline muted loop class="object-cover h-screen md:max-h-[720px] lg:max-h-[680px] w-full rounded-2xl">
        <source src="../assets/video2.mp4" type="video/mp4"/>
      </video>
    </div>
  </div>

  <!-- Products -->
  <div id="products" ref="products" class="max-w-6xl mx-auto w-full px-4 py-24 font-oswald">
    <div v-if="!loading" class="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-16">
      <div
          v-for="item in items"
          :key="item.id"
      >
        <div class="mb-4 cursor-pointer" @click="openItem(item)">
          <img :src="'https://admin.eatandchill.kz'+item.image" class="w-full h-44 md:h-72 object-cover object-center rounded-2xl mb-2" :alt="item.name" />
          <p class="mb-1 text-xl">{{item.name}}</p>
          <p class="text-sm font-roboto text-gray-600 line-clamp-2">{{item.composition}}</p>
        </div>

        <div class="flex justify-between items-center">
          <span class="text-lg font-medium">{{item.price}}₸</span>
          <button v-if="isInCart(item.id)" @click="removeFromCart(item)" class="border border-black hover:bg-gray-100 text-xs md:text-sm px-4 py-2 rounded-2xl uppercase flex">
            <span>убрать</span>
          </button>

          <button v-else @click="addToCart(item)" class="bg-black hover:bg-white hover:text-black hover:border-black border text-white text-xs md:text-sm px-4 py-2 rounded-2xl uppercase flex">
            <span>добавить</span>
          </button>
        </div>
      </div>
    </div>

    <div v-else class="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-16">
      <div class="animate-pulse">
        <div class="w-full h-44 bg-gray-400 rounded-2xl mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-40 h-8 mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-24 h-8"></div>
      </div>

      <div class="animate-pulse">
        <div class="w-full h-44 bg-gray-400 rounded-2xl mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-40 h-8 mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-24 h-8"></div>
      </div>

      <div class="animate-pulse">
        <div class="w-full h-44 bg-gray-400 rounded-2xl mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-40 h-8 mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-24 h-8"></div>
      </div>

      <div class="animate-pulse">
        <div class="w-full h-44 bg-gray-400 rounded-2xl mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-40 h-8 mb-3"></div>
        <div class="bg-gray-300 rounded-2xl w-24 h-8"></div>
      </div>
    </div>
  </div>

  <product-details v-if="showItem" :item="item" @close="closeItem" @add="addToCart" @remove="removeFromCart"/>
</template>

<script>
import axios from "axios";
import ProductDetails from "@/components/ProductDetails.vue";
import {mapGetters} from "vuex";

export default {
  name: 'HomeView',
  components: {ProductDetails},
  data: () => ({
    items: [],
    item: {},
    showItem: false,
    loading: true
  }),
  computed: {
    ...mapGetters({
      isInCart: 'hasItem'
    })
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      axios.get('https://admin.eatandchill.kz/api/products-all', {
        params: {
          brandId: 1
        }
      }).then(res => {
        this.items = res.data.data
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    openItem(item) {
      this.item = item
      this.showItem = true
    },
    closeItem() {
      this.item = {}
      this.showItem = false
    },
    addToCart(item) {
      this.$store.dispatch('addToCart', {
        id: item.id,
        name: item.name,
        image: item.image,
        price: item.price,
        wholesale: item.wholesalePrice,
        q: 1,
        total: item.price
      });
    },
    removeFromCart(item) {
      this.$store.dispatch('removeFromCart', item);
    },
    scrollDown() {
      this.$refs.products.scrollIntoView()
    }
  }
}
</script>
