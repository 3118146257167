<template>
  <div class="w-full h-screen fixed z-40 inset-0 bg-black">
    <div class="w-full h-screen flex justify-center items-center">
      <div class="max-w-2xl relative w-full h-screen overflow-scroll md:overflow-hidden md:h-[510px] md:rounded-2xl px-4 py-8 flex flex-col md:flex-row items-center gap-4 backdrop-filter backdrop-blur-md bg-white">
        <div class="md:w-1/2">
          <img :src="'https://admin.eatandchill.kz' + item.image" class="h-[620px] md:h-[480px] w-full rounded-2xl object-cover object-center"/>
        </div>

        <div class="md:w-1/2 h-full font-oswald p-4 flex flex-col justify-between gap-10">
          <div>
            <p class="font-medium text-2xl mb-3">{{item.name}}</p>

            <div class="flex items-center gap-x-10 mb-5">
              <div>
                <p class="text-sm font-light">Белки</p>
                <p class="text-lg">{{item.protein}}</p>
              </div>

              <div>
                <p class="text-sm font-light">Жиры</p>
                <p class="text-lg">{{item.fat}}</p>
              </div>

              <div>
                <p class="text-sm font-light">Углеводы</p>
                <p class="text-lg">{{item.carb}}</p>
              </div>

              <div>
                <p class="text-sm font-light">Ккал</p>
                <p class="text-lg">{{item.kcal}}</p>
              </div>
            </div>

            <p class="font-light md:text-lg font-roboto">{{item.composition}}</p>
          </div>

          <div class="w-full block">
            <button v-if="isInCart(item.id)" @click="$emit('remove', item)" class="bg-white hover:bg-gray-200 border border-black text-xs md:text-sm py-3.5 text-center rounded-2xl uppercase flex justify-center w-full mb-3">
              убрать
            </button>

            <button v-else @click="$emit('add', item)" class="bg-black hover:bg-white hover:text-black border border-black text-white text-xs md:text-sm py-3.5 text-center rounded-2xl uppercase flex justify-center w-full mb-3">
              {{item.price}} тг
            </button>

            <button @click="$emit('close')" class="bg-white hover:bg-gray-100 border border-black text-xs md:text-sm py-2.5 text-center rounded-2xl uppercase flex justify-center w-full">
              закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProductDetails",
  props: {
    item: Object
  },
  computed: {
    ...mapGetters({
      isInCart: 'hasItem'
    })
  }
}
</script>