<template>
  <div class="relative">
    <navbar/>
    <router-view></router-view>
  </div>
</template>

<script>
import Whatsapp from "@/assets/whatsapp.vue";
import Cart from "@/assets/cart.vue";
import HomeView from "@/views/HomeView.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: 'App',
  components: {Navbar, HomeView, Whatsapp, Cart}
}
</script>