<template>
  <!-- Hero -->
  <div class="flex flex-col lg:flex-row items-center justify-between lg:gap-10 md:h-[500px] px-2 lg:relative mb-20 font-oswald">
    <div class="w-full lg:w-1/2 h-full flex flex-col justify-between bg-white bg-opacity-40 backdrop-filter backdrop-blur-sm rounded-2xl p-4 lg:p-0">
      <div>
<!--        <span class="bg-green-600 manrope rounded-2xl px-4 py-1 text-white text-sm mb-3">Халал</span>-->
        <h1 class="text-4xl lg:text-6xl text-gray-900 font-medium manrope mb-3 md:mb-5 mt-2">
          Ресторанные блюда в шоковой заморозке
        </h1>

        <p class="manrope text-lg lg:text-2xl mb-10 md:text-gray-700 font-light">
          Мы готовим изысканные блюда, которые Вы можете быстро подать к столу
        </p>

        <button class="bg-black hover:bg-white hover:text-black hover:border-black border text-white text-sm px-8 py-2 md:py-3 rounded-2xl uppercase manrope mb-12 flex">
          <span class="mr-1">Заказать</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
          </svg>
        </button>
      </div>

      <div class="flex flex-col md:flex-row justify-between rubik gap-4">
        <div class="w-full rounded-2xl border p-3 bg-gray-200 flex items-center lg:items-start lg:flex-col">
          <div class="flex items-center">
              <span class="text-xl md:text-2xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                01
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="manrope lg:mt-1 ml-3 lg:ml-0 text-sm">Разрежьте упаковку</p>
        </div>

        <div class="w-full rounded-2xl border p-3 bg-gray-200 flex items-center lg:items-start lg:flex-col">
          <div class="flex items-center">
              <span class="text-xl md:text-2xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                02
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="manrope lg:mt-1 ml-3 lg:ml-0 text-sm">Разогрейте в течение 5-8 минут</p>
        </div>

        <div class="w-full rounded-2xl border p-3 bg-gray-200 flex items-center lg:items-start lg:flex-col">
          <div class="flex items-center">
              <span class="text-xl md:text-2xl font-bold bg-gradient-to-r from-slate-600 to-teal-400 bg-clip-text text-transparent mr-1">
                03
              </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
          </div>

          <p class="manrope lg:mt-1 ml-3 lg:ml-0 text-sm">Наслаждайтесь</p>
        </div>
      </div>
    </div>

    <div class=" w-full lg:w-1/4 md:h-[600px] lg:h-full lg:relative lg:overflow-hidden">
      <video autoplay playsinline muted loop preload="" class="object-cover absolute -z-50 top-0 left-0 w-full h-[640px] lg:h-full">
        <source src="../assets/video2.mp4" type="video/mp4"/>
      </video>
    </div>
  </div>

  <div class="w-full px-4 py-24 manrope">
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 md:gap-16">
      <div
          v-for="(item, i) in items"
          :key="item.id"
      >
        <img :src="'https://admin.eatandchill.kz'+item.image" class="w-full h-44 md:h-72 object-cover object-center rounded-2xl mb-2" :alt="item.name" />
        <p class="mb-1 text-lg">{{item.name}}</p>
        <p class="mb-2 rubik text-gray-500 text-sm line-clamp-2">{{item.composition}}</p>
        <span class="text-lg font-medium">{{item.price}}₸</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'HomeView',
  data: () => ({
    items: []
  }),
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      axios.get('https://admin.eatandchill.kz/api/products-all', {
        params: {
          brandId: 1
        }
      }).then(res => {
        console.log(res.data.data)
        this.items = res.data.data
      })
    }
  }
}
</script>
